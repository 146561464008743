@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';

/* fonts  */
@import url('https://fonts.googleapis.com/css?family=Raleway:500|Playfair+Display:400i,700');

body {
  background: white;
  background-image: linear-gradient(45deg, rgba(209, 180, 77, 0.2), #FFFFFF);
  background-attachment: fixed;	
  background-size: cover;
}

.ReactGridGallery_tile-viewport img {
  margin-left: unset !important;
}